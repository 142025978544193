<template>
    <div class="PartnerFooter">
        <div class="contact-text">
          {{ displayTextWrap('Contact us at') }} <a href="mailto:contact@sawbo-animations.org">contact@sawbo-animations.org</a>
          <br><br>
          Scientific Animations Without Borders &copy; {{ currentYear }} | <a href="https://sawbo-animations.org/disclaimer/" target="_blank">{{ displayTextWrap('Disclaimer') }}</a>
        </div>
        <div v-if="showDisclaimerText" class="disclaimer-text">
          <i>{{ displayDisclaimer('usaid-disclaimer') }}</i>
        </div>
        <div class="footer-logos">
          <div 
            v-for="logo in logos" 
            :key="logo.file" 
            class="footer-logo-cont" 
            :style="{ width: logoContWidth }"
          >
            <a v-if="logo.link" :href="logo.link" target="_blank">
              <img 
                :src="logo.file"
                :class="logo.class"
              >
            </a>
            <img v-else 
              :src="logo.file"
              :class="logo.class"
            >
          </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PartnerFooter',
  props: {
  },
  data () {
      return {
      }
  },
  computed: {
    ...mapGetters([
      'organization',
      'siteInfo',
      'displayText',
      'displayTextCode',
    ]),
    currentYear() {
      let d = new Date();
      return d.getFullYear();
    },
    showSecondaryLogo() {
      return false;
    },
    logos() {
      return this.siteInfo.footerLogos;
    },
    showDisclaimerText() {
      return this.siteInfo.showDisclaimerText;
    },
    showTranslations() {
      return this.siteInfo.showTranslations;
    },
    logoContWidth() {
      let len = this.logos.length;
      return Math.floor( 100/len ) + "% !important";
    }
  },
  methods: {
    displayTextWrap(text) {
      return this.displayText(text, 'footer');
    },
    displayDisclaimer(code) {
      return this.displayTextCode(code, 'footer');
    }
  }
}
</script>

<style scoped>
.PartnerFooter {
  width: 100%;
  padding: 2% 5%;
  border-top: 2px solid #fafafa;
}
.contact-text {
  width: 100%;
  font-size: .8em;
  text-align: center;
  padding-bottom: 2%;
  border-bottom: 2px solid #fafafa;
}
.contact-text a {
    color: #3B5783;
}
.disclaimer-text {
  color: #414141;
  font-size: .8em;
  text-align: justify;
}
.footer-logos {
  width: 100%;
  padding-top: 1%;
}
.footer-logo-cont {
  display: inline-block;
  padding: 0;
}
.footer-logo {
  width: 100%;
}
.partner-logo {
  width: 70% !important;
  float: left;
}
.secondary-partner-logo {
  width: 80% !important;
}
.usaid-logo {
  width: 90% !important;
  float: left;
  padding-bottom:8px
}
.cimmyt-logo {
  width: 90% !important;
  padding-bottom: 10%;
  margin-left: 5%;
  float: left;
}
.iita-logo {
  width: 85% !important;
  margin-left: 5%;
  float: left;
}
.purdue-logo {
  width: 85% !important;
  padding-bottom: 6%;
  margin-left: 5%;
  float: left;
}
.msu-logo {
  width: 90% !important;
  margin-left: 15%;
  float: left;
}
.sawbo-logo {
  width: 90% !important;
  float: right;
  margin-right: 2%;
  padding-bottom: 3%;
}
.w-90 {
  width: 90% !important;
}
.w-80 {
  width: 80% !important;
}
.w-70 {
  width: 70% !important;
}
.w-60 {
  width: 60% !important;
}
.w-50 {
  width: 50% !important;
}
.w-40 {
  width: 40% !important;
  margin-left: 40%;
}
.w-35 {
  width: 35% !important;
  margin-left: 40%;
}
.w-30 {
  width: 30% !important;
}
.w-20 {
  width: 20% !important;
}
.w-10 {
  width: 10% !important;
}
</style>
