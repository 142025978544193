import sysOutDev from '../../data/sysOut';

let organization

if (process.env.NODE_ENV === 'development') {
    organization = sysOutDev.organization;
} else {
    organization = window.sysOut.organization;
}

const state = {
    organization: organization
}

const mutations = {
}

const actions = {
}

const getters = {
    organization: state => {
        return state.organization;
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}