<template>
    <div v-html="compiledHtml">
    </div>
</template>

<script>

import Axios from 'axios';

export default {
  name: 'HtmlLoader',
  props: {
    filename: {
        type: String,
        required: true,
    }
  },
  data () {
      return {
        input: ""
      }
  },
  computed: {
    fileUrl() {
      let url;
      if (process.env.NODE_ENV === 'development') {
          url = "https://sawbo-animations.org/AIDI_IITA/" + this.filename;
      } else {
          url = "./" + this.filename;
      }
      return url;
    },
    compiledHtml() {
          return this.input;
        }
  },
  methods: {
    loadFile() {
          Axios({
            method: "get",
            url: this.fileUrl
          })
            .then(result => {
              this.input = result.data;
            })
            .catch(error => {
              console.error("error getting file");
              console.error(JSON.stringify(error));
            });
        }
  },
  created() {
    console.log(this.fileUrl);
    this.loadFile();
  }
}
</script>

<style>
h2 {
  padding-bottom: 1.5%;
}
p {
  padding-bottom: 2.5%;
}
ul {
  margin-left: 15px;
}
ol {
  margin-left: 15px;
}
</style>
