<template>
  <div class="page">
    <section class="about">
      <html-loader :filename="currentFile" :key="currentFile" />
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HtmlLoader from "../components/utils/HtmlLoader";

export default {
  name: "About",
  components: {
    HtmlLoader
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
        'organization',
        'siteInfo',
        'currentLanguage',
        'aboutFiles'
    ]),
    currentFile() {
      let filename = "aboutFile.html";
      if (this.currentLanguage !== "EN") {
        filename = this.aboutFiles[this.currentLanguage];
      }
      return filename;
    }
  },
  methods: {
  },
};
</script>

<style scoped>
.about {
  padding-bottom: 2%;
}
.blue-hr {
  color: #3b5783;
  margin: 4% 0;
}
.language-buttons {
  width: 100%;
  padding: 2% 0;
}
.language-button {
  padding: 1.5%;
  margin-right: 2.5%;
  background-color: #3b5783;
  color: #FFF;
  border: none;
  cursor: pointer;
}
</style>
