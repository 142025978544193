<template>
  <div class="page">
      <!-- <h2>Vidéos | Videos</h2><br> -->
      <h2>Videos</h2><br>
      <video-list
        v-if="haveVideos"
        :videoList="videosList"
      />
      <coming-soon 
        v-else
        class="coming-soon"
      />
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

import VideoList from '../components/video/VideoList'
import ComingSoon from '../components/utils/ComingSoon'

export default {
  name: 'Videos',
  metaInfo() {
    return {
      title: "SAWBO Videos",
      meta: [
        {
          name: "description",
          content: "Watch, download, and share SAWBO RAPID Videos."
        },
        { property: "og:title", content: "SAWBO Videos" },
        { property: "og:image", content: "https://sawbo-animations.org/images/social_media/SAWBO_FB_Share.png" },
        { property: "og:url", content: window.location.origin + window.location.pathname },
        { property: "og:type", content: "website" }
      ],
    };
  },
  components: {
    VideoList,
    ComingSoon
  },
  data() {
      return {
      }
  },
  computed: {
      ...mapGetters([
        'videosList',
      ]),
      haveVideos() {
        //console.log("videoList",this.videosList);
        if (this.videosList.length > 0) {
          return true;
        }
        return false;
      }
  },
  methods: {
  },
  created() {
  }
}
</script>

<style scoped>
.coming-soon {
  width: 100%;
  padding-bottom: 2%;
}
</style>
